import React from 'react'
import HomeLayout from '@layouts/HomeLayout'

const IndexPage = props => {
  return (
    <HomeLayout>
      <div className='content'>
        <br />
        <h1>
          Thank you for signing up! 
          Check your email in a few minutes{' '}
        </h1>
      </div>
    </HomeLayout>
  )
}

export default IndexPage
